// extracted by mini-css-extract-plugin
export var col = "factionInfo-module--col--uUczX";
export var factionInfoContent = "factionInfo-module--faction-info-content--4DL4+";
export var factionInfoContentAdvisorBio = "factionInfo-module--faction-info-content-advisor-bio--+6x0F";
export var factionInfoContentAdvisorBioGradientBottom = "factionInfo-module--faction-info-content-advisor-bio-gradient-bottom--ZkkK7";
export var factionInfoContentAdvisorBioGradientTop = "factionInfo-module--faction-info-content-advisor-bio-gradient-top--7pPyw";
export var factionInfoContentAdvisorBioText = "factionInfo-module--faction-info-content-advisor-bio-text--njh31";
export var factionInfoContentDesc = "factionInfo-module--faction-info-content-desc--300IA";
export var factionInfoContentHeaderImg = "factionInfo-module--faction-info-content-header-img--7myGc";
export var factionInfoContentStats = "factionInfo-module--faction-info-content-stats--kC0Ju";
export var factionInfoPage = "factionInfo-module--faction-info-page--2-4Pc";
export var factionInfoTabs = "factionInfo-module--faction-info-tabs--68npV";
export var factionInfoTabsEcoActive = "factionInfo-module--faction-info-tabs-eco-active--Pv180";
export var factionInfoTabsIndustrialActive = "factionInfo-module--faction-info-tabs-industrial-active--rFq2s";
export var factionInfoTabsItem = "factionInfo-module--faction-info-tabs-item--vzDeD";
export var factionInfoTabsItemImg = "factionInfo-module--faction-info-tabs-item-img--8g+ay";
export var factionInfoTabsTechActive = "factionInfo-module--faction-info-tabs-tech-active--XC3FW";
export var factionInfoTabsTraditionalActive = "factionInfo-module--faction-info-tabs-traditional-active--8Jy3R";